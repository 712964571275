import { useState, useEffect, useCallback, forwardRef } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import './index.scss';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ProductCard from '../ProductCard';
import ProductAdd from '../ProductAdd';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Button from '../Shared/Button';
import '../../components/UploadFooter/index.scss';

import Loader from '../../components/Shared/Loader';
import {
  getUploadedCampaign,
  deleteCampaign,
  uploadCampaign,
  sendForApproval,
  getAssetFilterData,
  resendAsset,
} from '../../services/CampaignService';
import { showErrorForDuration } from '../../helpers/utilities';
import PopUp from '../../components/PopUp';
import actionIcon from '../../assets/images/icons/actionIcon.png';
import approveIcon from '../../assets/images/icons/approveIcon.png';
import { customStyles, CustomClearIndicator, CustomDropdownIndicator } from '../../helpers/utilities';
import PermissionsGate from '../../components/PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const Upload = () => {
  const [uploadedCampaigns, setUploadedCampaigns] = useState<any[]>([]);
  const [approvedCampaings, setApprovedCampaings] = useState<any[]>([]);
  const [pendingApprovalAssests, setPendingApprovalAssests] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [marketList, setMarketList] = useState<any[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<any>([]);
  const [selectedMarketValue, setSelectedMarketValue] = useState('');
  const [assetTypeList, setAssetTypeList] = useState<any[]>([]);
  const [selectedAssetType, setSelectedAssetType] = useState<any>([]);
  const [selectedAssetTypeValue, setSelectedAssetTypeValue] = useState('');
  const [formatList, setFormatList] = useState<any[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<any>([]);
  const [selectedFormatValue, setSelectedFormatValue] = useState('');
  const [conceptList, setConceptList] = useState<any>([]);
  const [selectedConcept, setSelectedConcept] = useState<any>([]);
  const [selectedConceptValue, setSelectedConceptValue] = useState('');
  const [brandList, setBrandList] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<any>([]);
  const [selectedBrandValue, setSelectedBrandValue] = useState('');
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFailed, setShowFailed] = useState(false);
  const [fileError, setFileError] = useState('');
  const [rejectedAssests, setRejectedAssests] = useState<any[]>([]);
  const [confirmDeletePopUp, setConfirmDeletePopUp] = useState(false);
  const [reUploadPopUp, setReUploadPopUp] = useState(false);
  const [deleteAssetId, setDeleteAssetId] = useState('');
  const [reuploadAssetTypeValue, setReuploadAssetTypeValue] = useState('');
  const [reuploadFormatValue, setReuploadFormatValue] = useState('');
  const [reuploadFileError, setReuploadFileError] = useState('');
  const [reuploadFile, setReuploadFile] = useState(null);
  const [selectedAssetData, setSelectedAssetData] = useState<any>(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [goLiveDate, setGoliveDate] = useState<any>(new Date());
  const presentationMode = !window.sessionStorage.getItem('activatedMode');
  const isUploadDisabled =
    !selectedAssetTypeValue ||
    !presentationMode ||
    (selectedAssetTypeValue === 'Brand' &&
      (!selectedFormatValue ||
        !selectedBrandValue ||
        !selectedConceptValue ||
        !selectedMarketValue ||
        !selectedFile)) ||
    (selectedAssetTypeValue !== 'Brand' && (!selectedBrandValue || !selectedFile));
  const isReuploadDisabled = !reuploadFile || !presentationMode;

  const CustomDatepickerInput = forwardRef<any, any>(({ label, value, onClick }, ref) => (
    <button className="customDatepickerInput" onClick={onClick} ref={ref}>
      {moment(value).format('DD/MM/YYYY')}
    </button>
  ));

  useEffect(() => {
    getUploadCampaigns();
    getAssetFiltersData();
  }, []);

  let partnerId: any = '';
  if (partnerDetail.partner && Object.keys(partnerDetail.partner).length !== 0) {
    partnerId = partnerDetail.partner.partnerId;
  } else {
    partnerId = window.sessionStorage.getItem('partnerId');
  }

  const getUploadCampaigns = () => {
    setShowLoader(true);
    getUploadedCampaign(partnerId)
      .then(function (data) {
        const sortedData = data
          .slice()
          .sort((a: any, b: any) => new Date(b?.uploadedOn).getTime() - new Date(a?.uploadedOn).getTime())
          .sort((a, b) => a?.brand.localeCompare(b?.brand));
        let newCampaigns: any = sortedData.filter((campaign: any) => {
          if (campaign.status == 'New') {
            return campaign;
          }
        });

        setUploadedCampaigns(newCampaigns);
        getApprovedCampaigns(sortedData);
        getPendingApprovalAssets(sortedData);
        getRejectedAssets(sortedData);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getAssetFiltersData = () => {
    setShowLoader(true);
    getAssetFilterData(partnerId)
      .then(function (data) {
        const transformedAssetType = data?.assetTypes?.map((asset) => ({
          value: asset.assetTypeId,
          label: asset.assetTypeName,
        }));
        const transformedBrands = data?.brands?.map((brand) => ({ value: brand.brandId, label: brand.brandName }));
        const transformedConcept = data?.concepts?.map((concept) => ({
          value: concept.conceptId,
          label: concept.conceptName,
        }));
        const transformedFormat = data?.formats?.map((format) => ({
          value: format.formatId,
          label: format.formatName,
        }));
        const transformedMarket = data?.markets?.map((market) => ({
          value: market.marketId,
          label: market.marketName,
        }));
        setAssetTypeList(transformedAssetType);
        setBrandList(transformedBrands);
        setConceptList(transformedConcept);
        setFormatList(transformedFormat);
        setMarketList(transformedMarket);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const getApprovedCampaigns = (data: any) => {
    let newCampaigns: any = data.filter((campaign: any) => {
      if (campaign.status == 'Approved' || campaign.status == 'Downloaded') {
        return campaign;
      }
    });
    setApprovedCampaings(newCampaigns);
  };

  const getPendingApprovalAssets = (data: any) => {
    let newCampaigns: any = data.filter((campaign: any) => {
      if (campaign.status == 'Received') {
        return campaign;
      }
    });
    setPendingApprovalAssests(newCampaigns);
  };

  const getRejectedAssets = (data: any) => {
    let newCampaigns: any = data.filter((campaign: any) => {
      if (campaign.status == 'Rejected') {
        return campaign;
      }
    });
    setRejectedAssests(newCampaigns);
  };

  const deleteAsset = useCallback((uniqueId: string, status: string) => {
    let uniqueIds: any = [];
    uniqueIds.push(uniqueId);
    setDeleteAssetId(uniqueId);

    if (status === 'New') {
      deleteUploadedcampaign(uniqueIds);
    } else {
      setConfirmDeletePopUp(true);
    }
  }, []);

  const deleteUploadedcampaign = (uniqueIds: any) => {
    setShowLoader(true);
    deleteCampaign(uniqueIds)
      .then(function (data) {
        setDeleteAssetId('');
        getUploadCampaigns();
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const handleAssetType = (selectedOption: any) => {
    setSelectedAssetType(selectedOption);
    setSelectedAssetTypeValue(selectedOption?.value);

    if (selectedOption?.value === 'Brand') {
      handleMarket(marketList?.[0]);
    }

    if (!selectedOption?.value) {
      setSelectedFormat([]);
      setSelectedFormatValue('');
      setSelectedMarket([]);
      setSelectedMarketValue('');
      setSelectedConcept([]);
      setSelectedConceptValue('');
      setGoliveDate(new Date());
    }
  };

  const handleFormat = (selectedOption: any) => {
    setSelectedFormat(selectedOption);
    setSelectedFormatValue(selectedOption?.value);
  };

  const handleDiscard = () => {
    setSelectedAssetType([]);
    setSelectedAssetTypeValue('');
    setSelectedFormat([]);
    setSelectedFormatValue('');
    setSelectedMarket([]);
    setSelectedMarketValue('');
    setSelectedConcept([]);
    setSelectedConceptValue('');
    setSelectedBrand([]);
    setSelectedBrandValue('');
    setSelectedFile(null);
    setGoliveDate(new Date());
  };

  const handleMarket = (selectedOption: any) => {
    setSelectedMarket(selectedOption);
    setSelectedMarketValue(selectedOption?.value);
  };

  const handleBrand = (selectedOption: any) => {
    setSelectedBrand(selectedOption);
    setSelectedBrandValue(selectedOption?.value);
  };

  const handleConcept = (selectedOption: any) => {
    setSelectedConcept(selectedOption);
    setSelectedConceptValue(selectedOption?.value);
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleReuploadFileSelect = (file) => {
    setReuploadFile(file);
  };

  const handleUploadClick = () => {
    if (!selectedFile) {
      showErrorForDuration(setShowFailed, 3000);
      return;
    }

    setShowLoader(true);

    const formData = new FormData();
    const submitData = {
      AssetTypeName: selectedAssetType?.label,
      AssetTypeId: selectedAssetType?.value,
      BrandName: selectedBrand?.label,
      BrandId: selectedBrand?.value,
      ConceptName: selectedConcept?.label,
      ConceptId: selectedConcept?.value,
      GoLiveDate: selectedAssetType?.value === 'Brand' ? moment(goLiveDate).format('YYYY-MM-DD') : undefined,
      FormatName: selectedFormat?.label,
      FormatId: selectedFormat?.value,
      MarketName: selectedMarket?.label,
      MarketId: selectedMarket?.value,
      PartnerId: partnerId,
      UploadedBy: window.sessionStorage.getItem('userEmail'),
    };

    const jsonData = JSON.stringify(submitData);
    formData.append('campaignFile', selectedFile);
    formData.append('data', jsonData);

    uploadCampaign(formData)
      .then((response) => {
        const { success, error } = response;
        setShowLoader(false);
        if (!response || response.length === 0 || (success === null && error === null)) {
          showErrorForDuration(setShowFailed, 3000);
          return;
        }

        if (success) {
          getUploadCampaigns();
          handleDiscard();
        } else if (error) {
          setFileError(error);
        } else {
          showErrorForDuration(setShowFailed, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        showErrorForDuration(setShowFailed, 3000);
        setShowLoader(false);
      });
  };

  const handleReuploadClick = () => {
    if (!reuploadFile) {
      showErrorForDuration(setShowFailed, 3000);
      return;
    }

    setShowLoader(true);

    const formData = new FormData();
    const submitData = {
      rowkey: selectedAssetData?.rowKey,
      uploadedBy: window.sessionStorage.getItem('userEmail'),
    };

    const jsonData = JSON.stringify(submitData);

    formData.append('campaignFile', reuploadFile);
    formData.append('data', jsonData);

    resendAsset(formData)
      .then((response) => {
        const { success, error } = response;
        setShowLoader(false);
        if (!response || response.length === 0 || (success === null && error === null)) {
          showErrorForDuration(setShowFailed, 3000);
          return;
        }

        if (success) {
          getUploadCampaigns();
          handleReuploadDiscard();
          setSuccessPopup(true);
        } else if (error) {
          setReuploadFileError(error);
        } else {
          showErrorForDuration(setShowFailed, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        showErrorForDuration(setShowFailed, 3000);
        setShowLoader(false);
      });
  };

  const sendApproval = () => {
    setShowLoader(true);
    const selectedAssets = uploadedCampaigns.map((item) => item?.rowKey);

    let userName: any = '';
    if (user.user.username) {
      userName = user.user.username;
    } else {
      userName = window.sessionStorage.getItem('userEmail');
    }
    setShowLoader(true);
    sendForApproval(userName, selectedAssets)
      .then(function (data) {
        getUploadCampaigns();
        setShowLoader(false);
        setSentSuccess(true);
        setTimeout(() => {
          setSentSuccess(false);
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        showErrorForDuration(setShowFailed, 3000);
        setShowLoader(false);
      });
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeletePopUp(false);
  };

  const handleConfirmDelete = () => {
    setConfirmDeletePopUp(false);
    deleteUploadedcampaign(deleteAssetId);
  };

  const handleReUpload = (assetData) => {
    setSelectedAssetData(assetData);
    setReuploadAssetTypeValue(assetData?.assetTypeId);
    setReuploadFormatValue(assetData?.formatId);
    setReUploadPopUp(true);
  };

  const handleReuploadClose = () => {
    setReUploadPopUp(false);
  };

  const handleReuploadDiscard = () => {
    setSelectedAssetData(null);
    setReuploadAssetTypeValue('');
    setReuploadFormatValue('');
    setReuploadFileError('');
    setReuploadFile(null);
    setReUploadPopUp(false);
  };

  const handleSuccessClose = () => {
    setSuccessPopup(false);
  };

  const handleDateChange = (date: any) => {
    setGoliveDate(date);
  };

  return (
    <PermissionsGate scopes={[SCOPES.canCreateCampaigns]} redirect={true} canCreateCampaigns={true}>
      <div>
        <div className="Upload">
          <Tabs defaultActiveKey="Uploads">
            <Tab eventKey="Uploads" title="Uploads">
              <div className="Upload-box Upload-box--scroll Upload-box--rounded">
                <Row className="g-4 uploadContainer">
                  <Col xs={12} lg={9}>
                    <Row>
                      <Col xs={12} lg={4}>
                        <label className="u-bold">Asset Type</label>
                        <Select
                          isSearchable
                          isClearable
                          value={selectedAssetType}
                          onChange={handleAssetType}
                          options={assetTypeList}
                          placeholder="Select"
                          styles={customStyles}
                          components={{
                            ClearIndicator: CustomClearIndicator,
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                        />
                      </Col>
                      <Col xs={12} lg={4}>
                        <label className="u-bold">Brand</label>
                        <Select
                          isSearchable
                          isClearable
                          value={selectedBrand}
                          onChange={handleBrand}
                          options={brandList}
                          placeholder="Select"
                          styles={customStyles}
                          components={{
                            ClearIndicator: CustomClearIndicator,
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                        />
                      </Col>
                      {selectedAssetTypeValue === 'Brand' && (
                        <>
                          <Col xs={12} lg={4}>
                            <label className="u-bold">Concept</label>
                            <Select
                              isSearchable
                              isClearable
                              value={selectedConcept}
                              onChange={handleConcept}
                              options={conceptList}
                              placeholder="Select"
                              styles={customStyles}
                              components={{
                                ClearIndicator: CustomClearIndicator,
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                            />
                          </Col>
                          <Col xs={12} lg={4}>
                            <label className="u-bold">Preferred Go-live date</label>
                            <DatePicker
                              selected={goLiveDate}
                              onChange={(date) => handleDateChange(date)}
                              minDate={new Date()}
                              customInput={<CustomDatepickerInput />}
                              closeOnScroll={true}
                              showMonthDropdown
                              useShortMonthInDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </Col>
                          <Col xs={12} lg={4}>
                            <label className="u-bold">Format</label>
                            <Select
                              isSearchable
                              isClearable
                              value={selectedFormat}
                              onChange={handleFormat}
                              options={formatList}
                              placeholder="Select"
                              styles={customStyles}
                              components={{
                                ClearIndicator: CustomClearIndicator,
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                            />
                          </Col>
                          <Col xs={12} lg={4}>
                            <label className="u-bold">Market</label>
                            <Select
                              isSearchable
                              isClearable
                              value={selectedMarket}
                              onChange={handleMarket}
                              options={marketList}
                              placeholder="Select"
                              styles={customStyles}
                              components={{
                                ClearIndicator: CustomClearIndicator,
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col xs={12} lg={3}>
                    <ProductAdd
                      className="ProductAdd"
                      getUploadCampaigns={getUploadCampaigns}
                      uploadtext="Upload Asset"
                      selectedAssetTypeValue={selectedAssetTypeValue}
                      selectedFormatValue={selectedFormatValue}
                      onFileSelect={handleFileSelect}
                      onFileError={setFileError}
                    ></ProductAdd>
                    {fileError && <p className="fileError mt-2">{fileError}</p>}
                  </Col>
                  <Row className="text-end mt-5">
                    <Col xs={12} lg={7}></Col>
                    <Col xs={12} lg={3}>
                      <Button className="Button Button--transparentBlack discardButton" onClick={handleDiscard}>
                        <span>Discard</span>
                      </Button>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Button
                        className="Button Button--transparentBlack"
                        onClick={handleUploadClick}
                        disabled={isUploadDisabled}
                      >
                        <span>Upload</span>
                      </Button>
                    </Col>
                  </Row>
                </Row>
                {uploadedCampaigns.length > 0 && (
                  <div className="pt-5 listContainer">
                    <Row className="gx-4 gy-4">
                      <h6>Ready To Send</h6>
                      {uploadedCampaigns &&
                        uploadedCampaigns.map((campaign: any) => {
                          return (
                            <Col xs={12} lg={3}>
                              <ProductCard
                                className="ProductCard"
                                deleteAsset={deleteAsset}
                                campaign={campaign}
                              ></ProductCard>
                            </Col>
                          );
                        })}
                    </Row>
                    <Row className="text-end mt-5">
                      <Col>
                        <Button
                          className="Button Button--transparentBlack"
                          onClick={sendApproval}
                          disabled={!presentationMode}
                        >
                          <span>Send for Approval</span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="PendingApproval" title="Pending Approval">
              <div className="Upload-box Upload-box--scroll Upload-box--rounded">
                <Row className="g-4 uploadContainer">
                  {pendingApprovalAssests.length == 0 ? (
                    <Row>
                      <Col xs={12} lg={6} className="u-center">
                        <h2 className="Heading text-center">Empty</h2>
                        <p className="text-left">
                          Looks like there are no pending approval items yet. They will appear here after you’ve sent
                          your assets for approval.
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {pendingApprovalAssests &&
                    pendingApprovalAssests.map((campaign: any) => {
                      return (
                        <Col xs={12} lg={3}>
                          <ProductCard className="ProductCard" campaign={campaign} isExpand></ProductCard>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Tab>
            <Tab eventKey="Approved" title="Approved">
              <div className="Upload-box Upload-box--scroll Upload-box--rounded">
                <Row className="g-4 uploadContainer">
                  {approvedCampaings.length == 0 ? (
                    <Row>
                      <Col xs={12} lg={6} className="u-center">
                        <h2 className="Heading text-center">Empty</h2>
                        <p className="text-left">
                          Looks like there are no approved items yet. They will appear here if your uploaded assets get
                          approved.
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {approvedCampaings &&
                    approvedCampaings.map((campaign: any) => {
                      return (
                        <Col xs={12} lg={3}>
                          <ProductCard className="ProductCard" campaign={campaign} isExpand isDownload></ProductCard>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="Rejected"
              title={
                <>
                  <span>Rejected</span>
                  {rejectedAssests.length >= 1 && (
                    <span data-tooltip="Unactioned Rejected Assets">
                      <img src={actionIcon} alt="Approve" />
                    </span>
                  )}
                </>
              }
            >
              <div className="Upload-box Upload-box--scroll Upload-box--rounded">
                <Row className="g-4 uploadContainer">
                  {rejectedAssests.length == 0 ? (
                    <Row>
                      <Col xs={12} lg={6} className="u-center">
                        <h2 className="Heading text-center">Empty</h2>
                        <p className="text-left">
                          Looks like there are no rejected items yet. They will appear here if your uploaded assets get
                          rejected.
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {rejectedAssests &&
                    rejectedAssests.map((campaign: any) => {
                      return (
                        <Col xs={12} lg={3}>
                          <ProductCard
                            className="ProductCard"
                            deleteAsset={deleteAsset}
                            handleReUpload={handleReUpload}
                            campaign={campaign}
                            isExpand
                          ></ProductCard>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Tab>
          </Tabs>
        </div>

        <div className="u-marginTlg"></div>

        <br />
        <PopUp
          key="confirmDelete"
          className="confirmDeletePopUp"
          show={confirmDeletePopUp}
          handleClose={handleConfirmDeleteClose}
        >
          <p>
            Are you sure you want to delete this asset and its corresponding values? You are always able to upload new
            assets under “Uploads.”
          </p>
          <div className="text-center">
            <Button className="Button Button--transparentBlack discardButton" onClick={handleConfirmDelete}>
              Yes, Delete
            </Button>
            <Button className="ms-3 Button Button--outlined Button--iconFirst" onClick={handleConfirmDeleteClose}>
              Cancel
            </Button>
          </div>
        </PopUp>
        <PopUp key="reUpload" className="reUploadPopUp" show={reUploadPopUp} handleClose={handleReuploadClose}>
          <div className="reUploadContainer">
            <ProductAdd
              className="ProductAdd"
              getUploadCampaigns={getUploadCampaigns}
              uploadtext="Reupload Asset"
              selectedAssetTypeValue={reuploadAssetTypeValue}
              selectedFormatValue={reuploadFormatValue}
              onFileSelect={handleReuploadFileSelect}
              onFileError={setReuploadFileError}
            ></ProductAdd>
          </div>
          {reuploadFileError && <p className="fileError mt-2">{reuploadFileError}</p>}
          <div className="text-center mt-4">
            <Button className="Button Button--transparentBlack discardButton" onClick={handleReuploadDiscard}>
              Discard
            </Button>
            <Button
              className="ms-3 Button Button--outlined Button--iconFirst"
              disabled={isReuploadDisabled}
              onClick={handleReuploadClick}
            >
              Resend
            </Button>
          </div>
        </PopUp>
        <PopUp key="uploadSuccess" className="successPopup" show={successPopup} handleClose={handleSuccessClose}>
          <div className="text-center">
            <img src={approveIcon} alt="Approve" />
          </div>
          <p className="mt-3">Great work! Your assets have been successfully uploaded and sent for approval.</p>
          <p>You can find them under “Pending Approval”.</p>
          <div className="text-center">
            <Button className="ms-3 Button Button--outlined Button--iconFirst" onClick={handleSuccessClose}>
              Ok
            </Button>
          </div>
        </PopUp>
        {sentSuccess && (
          <div className="alert alert-dark show-failed">
            <span>
              Great work! Your assets have been successfully sent for approval. <br></br> You can find them under
              “Pending Approval”.
            </span>
          </div>
        )}
        {showLoader ? <Loader /> : ''}
        {showFailed ? (
          <div className="alert alert-danger show-failed">
            <strong>Failed!</strong> An unexpected error occurred. Please try again later.
          </div>
        ) : (
          ''
        )}
      </div>
    </PermissionsGate>
  );
};

export default Upload;
