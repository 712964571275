import { Route, Redirect, useLocation } from 'react-router-dom';

const ProtectedRoute = (props: any) => {
  let location = useLocation();
  let search = location?.search;
  let par = new URLSearchParams(search);
  let isLink = par.has('link');

  let idToken = sessionStorage.getItem('idToken');
  let redirectUrl = location?.pathname + location?.search;
  // if (location?.search.includes('pageType')) {
  //   if (isLink) {
  //     redirectUrl = location?.pathname + location?.search;
  //   } else {
  //     redirectUrl = location?.pathname + location?.search + '&link=click';
  //   }
  // } else {
  //   redirectUrl = location?.pathname + location?.search;
  // }
  return idToken ? (
    <Route path={props.path} component={props.component} {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
        state: { referrer: redirectUrl },
      }}
    />
  );
};

export default ProtectedRoute;
