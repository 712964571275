import { useState } from 'react';
import './index.scss';
import ColumnResizer from 'react-table-column-resizer';

const Header = (props: any) => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

    setSortingField(field);
    setSortingOrder(order);
    props.onSorting(field, order);
  };

  return (
    <thead>
      <tr key="head">
        <th></th>
        <th></th>
        {props.headers?.map(
          ({ name, tooltip, field, sortable }: { name: string; tooltip: string; field: string; sortable: boolean }) =>
            name ? (
              <>
                <th
                  className={tooltip && 'tool'}
                  data-tip={tooltip}
                  key={name}
                  onClick={() => (sortable ? onSortingChange(field) : null)}
                  style={{
                    maxWidth: '225px',
                    paddingLeft: '10px',
                  }}
                >
                  {name}

                  {sortingField && sortingField === field && (
                    <i className={sortingOrder === 'asc' ? 'icon-Arrows-Up' : 'icon-Arrows-Down'} />
                  )}
                </th>
                <ColumnResizer className="columnResizerHeader" />{' '}
              </>
            ) : null,
        )}
      </tr>
    </thead>
  );
};

export default Header;
