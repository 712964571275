import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Shared/Button';
import './index.scss';
import '../../components/Shared/Indent-hero/index.scss';
import Loader from '../../components/Shared/Loader';
import { getPartnerDetail } from '../../services/PartnersService';
import Constants from '../../types/Constants';
import { useDispatch } from 'react-redux';
import { updatePartner } from '../../store/actions/partnerAction';
import { updateCurrentPartnerUser } from '../../store/actions/currentPartnerUserAction';
import Select from 'react-select';
import { getUniquePortfolioBrands } from '../../helpers/utilities';

const Welcome = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [username, setUsername] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [showDashboard, setShowDashboard] = useState(true);
  const [userType, setUserType] = useState('');
  const user = useSelector((state: RootStateOrAny) => state.user);
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedPortfolioBrand, setSelectedPortfolioBrand] = useState<any[]>([]);
  const [portfolioBrandList, setPortfolioBrandList] = useState<any[]>([]);

  const location = useLocation<any>();

  let redirectUrl = location.state?.redirectUrl;
  let hasMultiplePartners = window.sessionStorage.getItem('hasMultiplePartners') === 'true' ? true : false;
  let multiplePartners = JSON.parse(window.sessionStorage.getItem('multiplePartners')?.toString() || '[]');
  const isLinkClick = redirectUrl?.includes('link=click');
  let par = new URLSearchParams(redirectUrl);
  let portfolioBrandValue = par.get('portfolioBrand');

  useEffect(() => {
    if (user.user.name) {
      setUsername(user.user.name);
    } else {
      let userInfo: any = window.sessionStorage.getItem('user');
      let userInfoDetail: any = JSON.parse(userInfo);
      setUsername(userInfoDetail.name);
    }
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
      loadUser(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
      loadUser(usertype);
    }
  }, []);

  const loadUser = (userTypeInfo: string) => {
    if (isLinkClick) {
      history.push(redirectUrl);
    } else {
      if (userTypeInfo == 'HmUser') {
        let brand = { value: '1', label: 'H&M' };

        switch (portfolioBrandValue) {
          case '2':
            brand = { value: '2', label: 'Arket' };
            break;
          case '3':
            brand = { value: '3', label: '& Other Stories' };
            break;
          case '4':
            brand = { value: '4', label: 'Monki' };
            break;
          case '5':
            brand = { value: '5', label: 'Weekday' };
            break;
        }

        window.sessionStorage.setItem('portfolioBrand', JSON.stringify(brand));
        if (redirectUrl && redirectUrl !== '/welcome') {
          history.push(redirectUrl);
        } else {
          setShowDashboard(true);
          setButtonText('Partner Profiles');
        }
      } else {
        if (!hasMultiplePartners) {
          let partnerId: any = window.sessionStorage.getItem('partnerId');
          setShowLoader(true);
          getPartnerDetail(partnerId)
            .then(function (partnerData) {
              window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partnerData));
              window.sessionStorage.setItem('partnerId', partnerId);
              window.sessionStorage.setItem('hasMultiplePartners', 'false');
              getUniquePortfolioBrandsList();
              if (getUniquePortfolioBrands()?.length === 1) {
                if (redirectUrl && redirectUrl !== '/welcome') {
                  history.push(redirectUrl);
                } else {
                  if (partnerData?.isOnboarded) {
                    setShowDashboard(true);
                    setButtonText('Home');
                  } else {
                    setShowDashboard(false);
                  }
                }
              }
              setShowLoader(false);
            })
            .catch(function (error) {
              console.log(error);
              setShowLoader(false);
            });
        }
      }
    }
  };

  const goNext = () => {
    const destinationUrl = redirectUrl || (userType === 'HmUser' ? '/partner-profile' : '/home');

    history.push(destinationUrl);

    if (userType !== 'HmUser' && !redirectUrl) {
      setButtonText('Home');
    }
    // if (userType == 'HmUser') {
    //   history.push('/partner-profile');
    // } else {
    //   history.push('/dashboard');
    //   setButtonText('Dashboard');
    // }
  };

  const redirectPage = () => {
    let partnerId: any = window.sessionStorage.getItem('partnerId');
    setShowLoader(true);
    getPartnerDetail(partnerId)
      .then(function (partnerData) {
        window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partnerData));
        window.sessionStorage.setItem('partnerId', partnerId);

        const defaultRoute = partnerData.isOnboarded ? '/home' : '/onboarding';

        if (redirectUrl && redirectUrl !== '/welcome') {
          history.push(redirectUrl);
        } else {
          history.push(defaultRoute);
        }

        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const SelectPartner = (partnerId: string) => {
    setPortfolioBrandList([]);
    setSelectedPortfolioBrand([]);
    setShowLoader(true);
    getPartnerDetail(partnerId).then(function (partnerData) {
      window.sessionStorage.setItem(Constants.SESSIONSTORAGE_PARTNERDETAIL, JSON.stringify(partnerData));
      window.sessionStorage.setItem('partnerId', partnerId);
      window.sessionStorage.setItem('hasMultiplePartners', 'true');
      // window.sessionStorage.setItem("multiplePartners", JSON.stringify(partnerData.partnerUserMapping));
      getUniquePortfolioBrandsList();

      if (getUniquePortfolioBrands()?.length === 1) {
        if (redirectUrl && redirectUrl !== '/welcome') {
          history.push(redirectUrl);
        } else {
          if (partnerData.isOnboarded) {
            history.push('/home');
          } else {
            history.push('/onboarding');
          }
        }
      }

      setShowLoader(false);
      dispatch(updatePartner(partnerData ? partnerData : null));
      dispatch(updateCurrentPartnerUser(partnerData ? partnerData : null));
    });
  };

  const handlePortfolioBrand = (selectedOption: any) => {
    setSelectedPortfolioBrand(selectedOption);
    window.sessionStorage.setItem('portfolioBrand', JSON.stringify(selectedOption));
    if (getUniquePortfolioBrands()?.length > 1) {
      redirectPage();
    }
  };

  const getUniquePortfolioBrandsList = (): any => {
    let portfolioBrandsList: any = getUniquePortfolioBrands();
    setPortfolioBrandList(portfolioBrandsList);
    if (portfolioBrandsList?.length === 1) {
      handlePortfolioBrand(portfolioBrandsList?.[0]);
    }
  };

  return (
    <div className="welcome-page">
      <div className="bg-area">
        <Container fluid="md">
          <Row className="justify-content-md-center">
            <Col xs={12} sm={12} md={7}>
              <div className="Indent-hero">
                <h1 className="Heading">
                  Hi {username}, <br /> WELCOME TO THE H&M GROUP PARTNER HUB
                </h1>
                {hasMultiplePartners && (
                  <div className="Indent-heroWrapper">
                    <p>Please pick what legal entity you would like to use.</p>
                    {multiplePartners &&
                      multiplePartners.map((partner: any) => {
                        return (
                          <Button
                            className="Button Button--offWhite Button--large"
                            onClick={() => SelectPartner(partner.partnerId)}
                            style={{ marginRight: '10px', marginBottom: '20px' }}
                          >
                            {partner.partnerName}
                          </Button>
                        );
                      })}
                  </div>
                )}

                {portfolioBrandList?.length > 1 && (
                  <>
                    <p className="portfolioBrand">Which brand would you like to log into?</p>
                    <div className="portfolioBrandSelect">
                      <Select
                        isSearchable
                        value={selectedPortfolioBrand}
                        onChange={handlePortfolioBrand}
                        options={portfolioBrandList}
                        placeholder="View as"
                      />
                    </div>
                  </>
                )}

                {!hasMultiplePartners && portfolioBrandList && portfolioBrandList?.length <= 1 && (
                  <div className="Indent-heroWrapper">
                    <p>We are excited to work with you.</p>
                    {showDashboard ? (
                      <Button className="Button Button--offWhite Button--large" onClick={goNext}>
                        Let's go to {buttonText}!
                      </Button>
                    ) : (
                      <Button
                        className="Button Button--offWhite Button--large"
                        onClick={() => history.push('/onboarding')}
                      >
                        Let's go to Onboarding!
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {showLoader ? <Loader /> : <></>}
      </div>
    </div>
  );
};

export default Welcome;
