import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './index.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DataTable from '../../components/DataTable';
import PermissionsGate from '../../components/PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';
import Hero from '../../components/Hero';
import HeroImage from '../../assets/images/insightImage.svg';

const Insights = (props: any) => {
  const [uniqueEntityList, setUniqueEntityList] = useState<any[]>([]);
  const [selectedBusinessModelId, setSelectedBusinessModelId] = useState('');

  const handleUniqueEntityList = (entityList) => {
    setUniqueEntityList(entityList);
  };

  const handleSelectedBusinessModelId = (businessModelId) => {
    setSelectedBusinessModelId(businessModelId);
  };

  return (
    <PermissionsGate redirect={true} scopes={[SCOPES.canAccessInsights]}>
      <div className="Insight Page-gradient">
        <Hero className="Hero Hero-gradient mb-5" heroImage={HeroImage}>
          <h1 className="Heading">Insights</h1>

          <p className="Hero-preamable mt-3">
            In this dashboard you can track the performance of your products at hm.com.
          </p>
          {uniqueEntityList?.length > 1 && !selectedBusinessModelId ? (
            <ul>
              <li>Choose an entity.</li>
            </ul>
          ) : null}
          {selectedBusinessModelId === '10001' ? (
            <p>
              Please note that data have a four day delay, hence you can only select from and to date at earliest four
              days back. Historical data retrieval is available from July 1st 2023 and onwards.{' '}
            </p>
          ) : null}

          {selectedBusinessModelId ? (
            <>
              <p>
                We advice you to interpret this as a high level overview. If you have any questions, contact{' '}
                <a href="mailto:partnersupport@hm.com"> partnersupport@hm.com</a>.{' '}
              </p>
              <ul>
                <li>Choose a start and end date for the period you wish to track.</li>
                {selectedBusinessModelId === '10002' ? <li>Choose a market.</li> : null}
              </ul>
            </>
          ) : null}
        </Hero>
        <Container fluid="xl">
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Tabs defaultActiveKey="Performance">
                <Tab eventKey="Performance" key="Performance" title="Performance">
                  <div className="users-table">
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <DataTable
                          handleUniqueEntityList={handleUniqueEntityList}
                          handleSelectedBusinessModelId={handleSelectedBusinessModelId}
                        />
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
    </PermissionsGate>
  );
};

export default Insights;
