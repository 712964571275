import { Redirect } from 'react-router-dom';
import { PERMISSIONS } from '../../helpers/permission-maps';
import useGetRoles from '../../helpers/useGetRole';
import getDetails from '../../api/EnviromentConfig';

const hasPermission = ({ roles, scopes }: { roles: string[]; scopes: string[] }) => {
  const scopesMap = new Set(scopes);
  return roles.some((role) => {
    const permissions = PERMISSIONS[role] || [];
    return permissions.some((permission) => scopesMap.has(permission));
  });
};

export default function PermissionsGate({
  children,
  scopes = [] as string[],
  redirect = false,
  canAccessSupport = false,
  canAccessSizes = false,
  canAccessDropShipInvoice = false,
  canCreateCampaigns = false,
  canApproveCampaigns = false,
  canPortfolioBrand = false,
}) {
  const roles = useGetRoles();
  const permissionGranted = hasPermission({ roles, scopes });
  const partnerId: any = window.sessionStorage.getItem('partnerId');
  const userEmail = window.sessionStorage.getItem('userEmail');
  const showNaviagtionPartnerIds = getDetails().showNaviagtionPartnerIds;
  const showNaviagtionEmails = getDetails().showNaviagtionEmails;
  const userType = window.sessionStorage?.getItem('userType');
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  let childrenData: any = <></>;

  if (!permissionGranted) {
    if (redirect) {
      if (userType === 'Partner') {
        return <Redirect to="/home" />;
      } else {
        return <Redirect to="/partner-profile" />;
      }
    }
    return null;
  }

  if (canAccessSupport) {
    if (JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.sapId) {
      return <>{children}</>;
    } else {
      return null;
    }
  }

  if (canAccessDropShipInvoice) {
    if (
      JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.shopId &&
      JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.isEinvoicingEnabled &&
      portfolioBrand?.value === '1'
    ) {
      return <>{children}</>;
    } else {
      if (redirect) {
        if (userType === 'Partner') {
          return <Redirect to="/home" />;
        } else {
          return <Redirect to="/partner-profile" />;
        }
      }
      return null;
    }
  }

  if (canCreateCampaigns) {
    if (JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.trademarkName && portfolioBrand?.value === '1') {
      return <>{children}</>;
    } else {
      if (redirect) {
        if (userType === 'Partner') {
          return <Redirect to="/home" />;
        } else {
          return <Redirect to="/partner-profile" />;
        }
      }
      return null;
    }
  }

  if (canPortfolioBrand) {
    if (portfolioBrand?.value === '1') {
      return <>{children}</>;
    } else {
      if (redirect) {
        if (userType === 'Partner') {
          return <Redirect to="/home" />;
        } else {
          return <Redirect to="/partner-profile" />;
        }
      }
      return null;
    }
  }

  if (canApproveCampaigns) {
    if (portfolioBrand?.value === '1') {
      return <>{children}</>;
    } else {
      if (redirect) {
        if (userType === 'Partner') {
          return <Redirect to="/home" />;
        } else {
          return <Redirect to="/partner-profile" />;
        }
      }
      return null;
    }
  }

  if (canAccessSizes) {
    const showSizes: any = ['10001', '10004'];
    const partnerEntityData: any = [];
    const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);
    let partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
    partnerData?.partnerEntity
      ?.filter((brand) => brand?.brandId === portfolioBrand?.value)
      .forEach((item: any) => {
        partnerEntityData.push(item.categoryId);
      });

    const uniquePartnerEntityData: any = partnerEntityData?.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });

    if (showSizes?.some((item) => uniquePartnerEntityData?.includes(item))) {
      childrenData = <>{children}</>;
    } else {
      return null;
    }
  } else {
    childrenData = <>{children}</>;
  }

  return childrenData;
}
