import { Row, Col } from 'react-bootstrap';
import './index.scss';

const MultiVideoBlock = (props: any) => {
  return (
    <>
      <div className="Article-content video-block" data-testid="VideoBlock">
        <div className={'Article-content--media'} id={props.block.data?.id}>
          <div className="Article-content--mediaContent">
            <label>{props.block.data?.overlineText}</label>
            <h3 className="Heading">{props.block.data?.heading}</h3>
            <p>{props.block.data?.description}</p>
          </div>
          <div className="Article-content--media">
            <Row>
              {props.block.data?.videos.map((video: any) => {
                return (
                  <Col lg={4}>
                    <video
                      controls
                      poster={props.block.data?.splashImage}
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    >
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default MultiVideoBlock;
