import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import Button from '../Shared/Button';
// import appInfo from '../../../package.json';
// import EntitySwitch from '../EntitySwitch/EntitySwitch';
import Select from 'react-select';
import { getUniquePortfolioBrands } from '../../helpers/utilities';
import { getAllFilters } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';
import { useHistory } from 'react-router-dom';

const ProfileWidget = (props: any) => {
  let history = useHistory();
  const userFullname = sessionStorage.getItem('userName');
  const user = useSelector((state: RootStateOrAny) => state.user);
  const portfolioBrand: any = JSON.parse(sessionStorage.getItem('portfolioBrand')!);
  const [selectedPortfolioBrand, setSelectedPortfolioBrand] = useState<any[]>([]);
  const [portfolioBrandList, setPortfolioBrandList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setSelectedPortfolioBrand(portfolioBrand);
    if (window.sessionStorage.getItem('userType') === 'HmUser') {
      getFilters();
    } else {
      getUniquePortfolioBrandsList();
    }
  }, []);

  const getFilters = () => {
    setShowLoader(true);
    getAllFilters()
      .then(function (data) {
        let portfolioBrandData: any = [];
        for (let brand of data.brands) {
          let brandData: any = { value: brand.brandId, label: brand.brandName };
          portfolioBrandData.push(brandData);
        }
        setPortfolioBrandList(portfolioBrandData?.sort((a, b) => a?.label?.localeCompare(b?.label, 'sv')));
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const handlePortfolioBrand = (selectedOption: any) => {
    setSelectedPortfolioBrand(selectedOption);
    props.portfolioBrand(selectedOption);
    const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);
    if (portfolioBrand?.value != selectedOption?.value) {
      if (window.sessionStorage.getItem('userType') == 'HmUser') {
        history.push('/partner-profile');
      } else {
        history.push('/home');
      }
      window.sessionStorage.setItem('portfolioBrand', JSON.stringify(selectedOption));
    }
  };

  const getUniquePortfolioBrandsList = (): any => {
    let portfolioBrandsList: any = getUniquePortfolioBrands();
    setPortfolioBrandList(portfolioBrandsList);
    if (portfolioBrandsList?.length === 1) {
      handlePortfolioBrand(portfolioBrandsList?.[0]);
    }
  };

  return (
    <div className="ProfileWidget" data-testid="profile-widget">
      <div></div>
      <div className="ProfileWidget-content">
        <div className="ProfileWidget-userImage">{/* future image */}</div>
        {portfolioBrandList?.length > 1 && (
          <Select
            isSearchable
            value={selectedPortfolioBrand}
            onChange={handlePortfolioBrand}
            options={portfolioBrandList}
            placeholder="Brand"
            className="mt-3 portfolioBrand"
          />
        )}
        <div className="ProfileWidget-user">
          <span className="ProfileWidget-userName">
            {userFullname ? userFullname : user?.user?.name}, Signed in as {portfolioBrand?.label}
          </span>
          {/* <span className="ProfileWidget-userName">v. {appInfo.version}</span> */}
          {/* <span className='ProfileWidget-userTitle'>Product Designer</span> */}
        </div>
        {/* <div className='Navigation-toggleText'> HIDDEN UNTIL WE ADD SETTINGS FOR PROFILE
                    <a href='#'><i className='icon-Setting-Circle'></i></a>
                </div> */}
      </div>

      <div className="u-textCenter">
        <Button
          className="Button Button--transparentBlack Button--iconFirst ProfileWidget-button"
          onClick={() => {
            props.logOut();
          }}
        >
          <i className="icon-Exit"></i>
          <span className=" Navigation-toggleText">Log out</span>
        </Button>
      </div>
      {showLoader ? <Loader /> : ''}
    </div>
  );
};
export default ProfileWidget;
