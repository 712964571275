import { useState, useEffect } from 'react';
import './index.scss';
import Select from 'react-select';
import { getEntityListForLearn, getUniqueEntityList } from '../../helpers/utilities';

const EntitySelector = (props: any) => {
  const [entityList, setEntityList] = useState<any[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<any[]>([]);
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!)?.value;

  const getEntityList = (): any => {
    const entityList: any = props.dataTable ? getUniqueEntityList() : getEntityListForLearn();
    setEntityList(entityList);
    if (entityList?.length === 1 || props.defaultSelect) {
      handleEntity(entityList?.[0]);
      window.sessionStorage.setItem('entitySelectedOption', entityList[0].value);
    }
    if (props.isEntity) {
      let entitySelectedOption: any = window.sessionStorage.getItem('entitySelectedOption');
      handleEntity(
        entityList?.filter((entity) => {
          return entity?.value === entitySelectedOption;
        })?.[0],
      );
    }
  };

  const handleEntity = (selectedOption: any) => {
    setSelectedEntity(selectedOption);
    props.handleEntity(selectedOption);
  };

  useEffect(() => {
    getEntityList();
  }, []);

  useEffect(() => {
    getEntityList();
  }, [portfolioBrand]);

  useEffect(() => {
    if (props.setSelectedEntity) {
      props.setSelectedEntity(selectedEntity);
    }
  }, [selectedEntity]);

  return entityList?.length > 0 ? (
    <Select
      isSearchable
      value={selectedEntity}
      onChange={handleEntity}
      options={entityList}
      placeholder={props.placeHolder ?? 'Entity'}
      isDisabled={entityList?.length === 1}
    />
  ) : null;
};

export default EntitySelector;
